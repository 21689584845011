<template>
  <div class="box">
  </div>
</template>

<script>

export default {
  name: 'SqlModel',
  data () {
    return {

    }
  },
  components: {},
  computed: {},
  beforeMount () {},
  mounted () {},
  methods: {},
  watch: {}

}

</script>
<style lang='scss' scoped>

</style>
